import styled from 'styled-components'
import { MobileAppDownload } from '@/components/UngatedHomePage/MobileAppDownload'
import { StarsOfShipt } from '@/components/UngatedHomePage/StarsOfShipt'
import { BagContainer } from '@/components/UngatedHomePage/BagContainer'
import { DeliveryDoneDifferently } from '@/components/UngatedHomePage/DeliveryDoneDifferently'
import { WhatCanWeDeliverForYou } from '@/components/UngatedHomePage/WhatCanWeDeliverForYou'
import { FAQ } from '@/components/UngatedHomePage/faq/FaqSection'
import { MemberShopperPhotos } from '@/components/UngatedHomePage/MemberShopperPhotos'
import { RetailerLogos } from '@/components/CMS/components/RetailerLogos'
import { Chat } from '@/components/Chat'
import { MembershipPricing } from '@/components/CMS/components/MembershipPricing'
import { ContinueInAppDialog } from '@/components/ContinueInAppDialog'
import { useAddressRemovalExperiment } from '@/components/AddressRemoval/useAddressRemovalExperiment'
import { useEffect } from 'react'
import {
  type RetailerLogosData,
  type MembershipPricingData,
  type SplitHeroV2Data,
  type ZipCheckData,
  type Content,
} from '@/components/CMS/types'
import { CMSSection } from '@/components/CMS/CMSSection'
import { SplitHeroV2 } from '@/components/CMS/components/SplitHeroV2'
import { ZipWrapper } from '@/components/Marketing/ZipCheckForm'
import { screenSizes } from '@shipt/design-system-themes'
import { GapManager } from '@/components/CMS/components/GapManager'
import {
  PartialCMSPageWithHeaderAndAnnouncement,
  type PartialCMSPageChildrenRenderProps,
} from '@/components/CMS/PartialCMSPageWithHeaderAndAnnouncement'
import { type InferGetServerSidePropsType } from 'next'
import { type getServerSideProps } from '@/pages'

type Props = InferGetServerSidePropsType<typeof getServerSideProps>

export const UngatedHomePage = ({ cmsData }: Props) => (
  <PartialCMSPageWithHeaderAndAnnouncement cmsData={cmsData}>
    {({ body, footer }) => (
      <UngatedHomePageContent body={body} footer={footer} />
    )}
  </PartialCMSPageWithHeaderAndAnnouncement>
)

const UngatedHomePageContent = ({
  body,
  footer,
}: Parameters<PartialCMSPageChildrenRenderProps>[0]) => {
  const { track, isVariant: isAddressRemoval } = useAddressRemovalExperiment()
  useEffect(() => track(), [track])

  const findData = <T extends Content>(
    id: Content['content_type_id']
  ): T | undefined => body?.find((c): c is T => c.content_type_id === id)

  const membershipPricingData =
    findData<MembershipPricingData>('membership_pricing')
  const zipCheckData = findData<ZipCheckData>('zip_check')
  const splitHeroV2Data = findData<SplitHeroV2Data>('split_hero_v2')
  const retailerLogosData = findData<RetailerLogosData>('retailer_logos')

  return (
    <>
      <GapManager>
        {splitHeroV2Data && (
          <UngatedHomepageSplitHeroV2
            {...splitHeroV2Data}
            data={{
              ...splitHeroV2Data.data,
              ...(isAddressRemoval &&
                zipCheckData && {
                  references: [
                    {
                      ...zipCheckData,
                      data: { ...zipCheckData.data, isAddressRemoval },
                    },
                  ],
                }),
            }}
          />
        )}
        {retailerLogosData && <RetailerLogos {...retailerLogosData} />}
      </GapManager>
      <DeliveryDoneDifferently />
      {membershipPricingData && (
        // This is temporary. Eventually, the ungated homepage will be migrated to the CMS and this (and other) components will be rendered dynamically.
        <div className="temporary-padding-manager">
          <MembershipPricing {...membershipPricingData} />
        </div>
      )}
      <MemberShopperPhotos />
      <BagContainer />
      <MobileAppDownload />
      <StarsOfShipt />
      <FAQ />
      <WhatCanWeDeliverForYou />
      {footer && <CMSSection section={footer} />}
      <Chat />
      <ContinueInAppDialog />
    </>
  )
}

const UngatedHomepageSplitHeroV2 = styled(SplitHeroV2)`
  ${ZipWrapper} {
    margin-top: 0;

    form {
      max-width: 20rem;
      margin: 0 auto;

      @media ${screenSizes.tablet} {
        max-width: none;
        flex-flow: row wrap;
      }
    }
  }
`
