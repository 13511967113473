import { atom } from 'jotai'

interface ProgressiveRevealState {
  counter: number
  product_index: number
}

export const progressiveRevealAtom = atom<ProgressiveRevealState>({
  counter: 0,
  product_index: 0,
})
progressiveRevealAtom.debugLabel = 'progressiveRevealAtom'
